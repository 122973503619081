import { apiCall, apiCallParams, logResponse } from "@/core/api";

export async function getWebhookList(): Promise<any> {
  const endpoint = "webhook/webhooks";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getWebhookListing(params: any):Promise<any> {
  const endpoint = "webhook/listing";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}

export async function getPayloadById(payloadId: string): Promise<any> {
  const endpoint = `/webhook/payload/${payloadId}`;
  const response = await apiCall("GET", endpoint, payloadId);
  if (response.code === 200) {
    return response.message;
  }
  return undefined;
}

export async function getWebhookById(payloadId: string): Promise<any> {
  const endpoint = `/webhook/${payloadId}`;
  const response = await apiCall("GET", endpoint, payloadId);
  if (response.code === 200) {
    return response.result;
  }
  return undefined;
}

export function isProcessed(webhookPayloadStatusType: string): boolean {
  switch (webhookPayloadStatusType) {
    case "PROCESSED":
    case "INPROGRESS":
      return true;
    case "UNPROCESSED":
    case "FAILED":
    case "ABORTED":
      return false;
    default:
      return false;
  }
}

export async function startPayload(webhookPayloadId: string): Promise<any> {
  const endpoint = `/webhook/${webhookPayloadId}/start`;
  const response = await apiCall("POST", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}
